import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Section from '../components/Section'
import InfoColumnSection from '../components/InfoColumnSection'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import sections from '../styles/sections.sass'
import heroStyles from '../styles/hero.sass';
import infocolumnsection from '../styles/infocolumnsection.sass'
import awardLogo from '../img/logo_aretsbyra.png'

export const PageTemplate = ({ title, content, media, acf, isAwardPage }) => {
  return (
    <>
      {media ?
      <Plx className="heroWrapper" parallaxData={ [ParallaxConfig('scale', 1, 0.9, 200)] }>
        <Plx className="plx heroImageWrapper" style={{height: '100%'}} parallaxData={ [ParallaxConfig('rotateX', 0, -2, 200, 'deg'),ParallaxConfig('rotateX', -2, 0, 200, 'deg', 10)] }>
          {media}
          {isAwardPage ? 
            <img className="hero-awardLogo" src={awardLogo} alt="aretsbyra_logo" />
          : null}
        </Plx>
      </Plx>
      : <div className="heroWrapperReplacer"></div>
      }
      { acf.text &&
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="post-intro" dangerouslySetInnerHTML={{ __html: acf.text }} />
            </div>
          </div>
        </div>
      </section>
    }
      {acf.sections && acf.sections.map((section, index) => (
        <Section section={section} index={index} key={"section-" + index} />
      ))}
    </>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0,0)
  })
  const { wordpressPage: page, allWordpressWpMedia: mediaResult } = data
  let heroMedia  = getMediaOrNull(page.acf, page.featured_media)

  let isAwardPage = false
  if (page.title === 'Årets Byrå' || page.title === 'Agency of the year') {
    isAwardPage = true
  }

  if (mediaResult !== null && (page.acf.text !== null || page.acf.sections !== null)) {
    const images = mediaResult.edges
    const imageExtension = /\.[jpegn]{3,}/
    const findAllImagesIncludeSize = /(-\d{2,4}x\d{2,4})?(\.[jpegn]{3,})/g

    // TODO: Test performance of this. Otherwise images must be separate sections until the image plugin is ready for use
    for(var i = 0; i < images.length; i++){
      if (images[i].node.localFile) {
        var currentImageName = images[i].node.source_url.replace(imageExtension,'')
        var replaceText = images[i].node.localFile.childImageSharp.sizes.src.replace(imageExtension,'')
        var findAllCurrentImages = new RegExp(currentImageName, 'g')
        if (page.acf.text !== null) {
          page.acf.text = page.acf.text.replace(findAllCurrentImages, replaceText)
          page.acf.text = page.acf.text.replace(findAllImagesIncludeSize, '$2')
        }

        if (page.acf.sections !== null) {
          for (var j = 0; j < page.acf.sections.length; j++) {
            if (page.acf.sections[j].text !== null) {
              page.acf.sections[j].text = page.acf.sections[j].text.replace(findAllCurrentImages, replaceText)
              page.acf.sections[j].text = page.acf.sections[j].text.replace(findAllImagesIncludeSize, '$2')
            }
          }
        }
      }
    }
  }

  return (
    <Layout title={page.title} metatitle={page.title} transparent={heroMedia == null ? false :  undefined} metadesc={page.yoast_meta.yoast_wpseo_metadesc} metaimage={page.featured_media ? page.featured_media.localFile.childImageSharp.fluid.src : (page.acf.featured_media ? page.acf.featured_media.localFile.publicURL : null)}>
      <PageTemplate title={page.title} content={page.content} media={heroMedia} acf={page.acf} isAwardPage={isAwardPage}/>
      <Footer />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

function getMediaOrNull(acf, image) {
  if (acf.featured_media) {
    if (acf.featured_media.mime_type.indexOf('image') === -1) {
      return (
        <div className="hero">
          <Plx parallaxData={ [ParallaxConfig('translateY', 0, 400, 1000)] } className="hero-mediaWrapper">
            <video className="hero-media" autoPlay muted playsInline loop>
                <source src={ acf.featured_media.localFile.publicURL } type={ acf.featured_media.mime_type }/>
            </video>
          </Plx>
        </div>
      )
    } else {
      return <div className="hero" style={{backgroundPosition: 'center', backgroundImage: 'url(' + acf.featured_media.localFile.publicURL + ')'}}></div>
    }
  } else if (image) {
    return <div className="hero" style={{backgroundPosition: 'center', backgroundImage: 'url(' + image.localFile.childImageSharp.fluid.src + ')'}}></div>
  } else {

    return null
  }
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!, $wordpress_id: Int!) {
    allWordpressWpMedia(
      filter : {
        post : {
          eq : $wordpress_id
        }
        mime_type : {
          in : ["image/jpeg", "image/png"]
        }
      }) {
      edges {
        node {
          post
          source_url
          localFile {
            extension
            childImageSharp {
              sizes {
                src
              }
            }
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 960) {
              src
              #srcWebp
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf{
        tagline
        text
        featured_media {
          mime_type
          localFile {
            publicURL
          }
        }
        sections {
          type
          header
          text
          profession
          email
          phone
          infogroup {
            header
            text
            profession
            email
            phone
            media {
             localFile {
             publicURL
             }
           }
          }
          media {
            mime_type
            localFile {
              publicURL
              childImageSharp{
                original{
                  height
                }
              }
            }
            alt_text
          }
        }
      }
    }
  }
`
